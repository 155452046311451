import * as React from "react"
import {Helmet} from "react-helmet";
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
      <Layout id={'content-page'}>
        <Helmet>
          <title>SG-1.online - Validator Set-up</title>
        </Helmet>
        <main>
          <section className="error-section">
            <div className={'container'}>
              <div className={'flex items-center h-full'}>
                <div className={'basis-full text-center'}>
                  <h1 className={'gradient-text'}>404</h1>
                  <span>Ooops... Something went wrong</span>
                  <em>Error 404. Page not Found.</em>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
